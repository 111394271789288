/* DESKTOP (DEFAULT) */
.frame {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 0.5rem;
    overflow: hidden;
}

.main-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.content-header {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: flex-start;
}

.classroom-content {
    display: flex;
    flex-direction: column;
    background-color: #d9d9d9;

    margin: 0.25rem;
    padding: 1.75rem;

    width: 100%;
}

.classroom-name {
    font-family: "Montserrat", Helvetica;
    font-size: 2rem;
    font-weight: 700;

    margin-bottom: 2rem;
}

/* QUADRANTS */
.quadrants {
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

/* HORIZONTAL (X-AXIS) */
.quadrants::before {
    content: "";
    position: absolute;

    width: 100%;
    height: 0;
    top: 50%;

    border-top: 0.125rem dotted #888888;
    z-index: 0;
}

/* VERTICAL (Y-AXIS) */
.quadrants::after {
    content: "";
    position: absolute;

    width: 0;
    height: 100%;
    left: 50%;

    border-left: 0.125rem dotted #888888;
    z-index: 0;
}

.quadrant {
    position: absolute;
    width: 50%;
    height: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 3rem;
    opacity: 0.5;
}

.top-left {
    background-color: #ffebcc;

    top: 0;
    left: 0;
}

.top-right {
    background-color: #ccffcc;

    top: 0;
    right: 0;
}

.bottom-left {
    background-color: #ffcccb;

    bottom: 0;
    left: 0;
}

.bottom-right {
    background-color: #cceeff;

    bottom: 0;
    right: 0;
}

.emoji {
    font-size: 5rem;
}

/* AXIS */
.y-axis {
    font-family: "Montserrat", Helvetica;
    font-size: 1.5rem;
    font-weight: 600;

    position: absolute;
    z-index: 1;
}

.y-axis.top {
    top: 1rem;
    left: 50%;

    transform: translateX(-50%);
}

.y-axis.bottom {
    bottom: 1rem;
    left: 50%;

    transform: translateX(-50%);
}

.x-axis {
    font-family: "Montserrat", Helvetica;
    font-size: 1.5rem;
    font-weight: 600;

    position: absolute;
    z-index: 1;
}

.x-axis.left {
    top: 50%;
    left: 1rem;

    transform: translateY(-50%);
}

.x-axis.right {
    top: 50%;
    right: 1rem;

    transform: translateY(-50%);
}

/* STUDENT ICON */
.student-profile {
    position: absolute;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    transform: translate(-50%, -50%);

    cursor: pointer;
    z-index: 2;
}

.student-pic {
    width: 4rem;
    height: 4rem;

    border-radius: 50%;
    border: 0.125rem solid black;

    object-fit: cover;
    transition: transform 0.2s ease-in-out;

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.student-pic:hover {
    transform: scale(1.1);

    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.15);
}

.student-profile .student-name {
    margin-top: 0.25rem;
    font-size: 0.75rem;
    color: #333;

    text-align: center;

    width: 8rem;
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
}

/* TABLET */
@media (max-width: 991px) {
    .classroom-name {
        font-size: 1.75rem;
    }

    .emoji {
        font-size: 5rem;
    }
}

/* MOBILE (HORIZONTAL) */
@media (max-width: 767px) {
    .classroom-name {
        font-size: 1.5rem;
    }

    .y-axis,
    .x-axis {
        font-size: 1.25rem;
    }

    .emoji {
        font-size: 4rem;
    }

    .student-pic {
        width: 3.5rem;
        height: 3.5rem;
    }
}

/* MOBILE (VERTICAL) */
@media (max-width: 478px) {
    .classroom-name {
        font-size: 1.25rem;
    }

    .y-axis,
    .x-axis {
        font-size: 1rem;
    }

    .emoji {
        font-size: 3rem;
    }

    .student-pic {
        width: 3.25rem;
        height: 3.25rem;
    }
}

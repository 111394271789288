.star-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 125px;
    height: 125px;
    margin: 0 auto;
}

.big-star {
    font-size: 75px;
    color: #f16100;
    animation: growAndSpark 1.25s ease-in-out;
}

@keyframes growAndSpark {
    0% {
        transform: scale(0.5);
        filter: brightness(1);
    }
    50% {
        transform: scale(1.5);
        filter: brightness(1.5);
    }
    100% {
        transform: scale(1);
        filter: brightness(1);
    }
}

.small-stars {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.tiny-star {
    position: absolute;
    font-size: 25px;
    color: #ffd700;
    opacity: 0;
    animation: popAndFly 2.5s ease-out forwards;
}

.star-1 {
    animation-delay: 0.2s;
    top: -30px;
    left: -30px;
}
.star-2 {
    animation-delay: 0.4s;
    top: -30px;
    right: -30px;
}
.star-3 {
    animation-delay: 0.6s;
    bottom: -30px;
    left: -30px;
}
.star-4 {
    animation-delay: 0.8s;
    bottom: -30px;
    right: -30px;
}
.star-5 {
    animation-delay: 1s;
    top: -50px;
}

@keyframes popAndFly {
    0% {
        transform: scale(0.5) translate(0, 0);
        opacity: 0;
    }
    50% {
        transform: scale(1.5) translate(0, 0);
        opacity: 1;
    }
    100% {
        transform: scale(1) translate(50px, 50px);
        opacity: 0;
    }
}

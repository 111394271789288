/* DESKTOP (DEFAULT) */
.question-prompts {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 1.25rem;
    padding: 1.25rem;
}

.question-prompt {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 22.5%;
    height: 100%;

    padding: 1.25rem;

    border-radius: 0.625rem;
    border: none;

    font-family: "Montserrat", Helvetica;
    color: #000000;

    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;

    overflow: hidden;
}

.question-prompt::before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-size: cover;
    background-position: center;

    opacity: 0.4;
    z-index: 1;
}

.prompt-1::before {
    background-image: url("../../icons/img/prompts/prompt-1.png");
}

.prompt-2::before {
    background-image: url("../../icons/img/prompts/prompt-2.png");
}

.prompt-3::before {
    background-image: url("../../icons/img/prompts/prompt-3.png");
}

.question-prompt p {
    position: relative;
    z-index: 2;
}

/* TABLET */
@media (max-width: 991px) {
    .question-prompts {
        gap: 1rem;
        padding: 1rem;
    }

    .question-prompt {
        width: 30%;
        padding: 1rem;
        font-size: 1.25rem;
    }
}

/* MOBILE (HORIZONTAL) */
@media (max-width: 767px) {
    .question-prompts {
        flex-direction: column;
        gap: 0.75rem;
        padding: 0.75rem;
    }

    .question-prompt {
        width: 80%;
        padding: 0.75rem;
        font-size: 1.125rem;
        background-image: none;
    }

    .question-prompt::before {
        background-image: none;
    }
}

/* MOBILE (VERTICAL) */
@media (max-width: 478px) {
    .question-prompts {
        gap: 0.5rem;
        padding: 0.5rem;
    }

    .question-prompt {
        width: 100%;
        padding: 0.5rem;
        font-size: 1rem;
        background-image: none;
    }

    .question-prompt::before {
        background-image: none;
    }
}

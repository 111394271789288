/* DESKTOP (DEFAULT) */
.user-input-content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    gap: 1rem;

    position: sticky;
    bottom: 0;
    z-index: 0;

    padding: 0.75rem 0.5rem;
}

.ask-content {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    width: 100%;
    background-color: #fff;
    padding: 0.25rem;
}

.ask-controls {
    display: flex;
    align-items: center;

    width: 100%;
    margin-left: 0.3125rem;
}

.mic-icon,
.photo-icon {
    width: 2rem;
    height: 2rem;
}

.ask-text {
    font-family: var(--m3-label-medium-font-family);
    font-size: 1.125rem;
    font-weight: var(--m3-label-medium-font-weight);
    color: #000;

    margin-left: 0.625rem;
    margin-right: 0.625rem;

    border: 0;
    width: 100%;

    resize: none;
    height: 1.5rem;
    min-height: 2.5rem;
    max-height: 10rem;

    overflow: auto;
    background-color: #f9f9f9;

    padding: 0.5rem;
    border-radius: 0.3125rem;
}

.send-button {
    background-color: #d3d0d0;
    padding: 0.625rem 1.25rem;
    color: #fff;
    border: 0;
    cursor: not-allowed;
    margin: 0;
    font-size: 1.125rem;
}

.send-button--active {
    background-color: #ff9e2c;
    cursor: pointer;
}

.icon-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.student-icon {
    width: 4.6875rem;
    height: 4.6875rem;
}

.earned-points {
    font-family: "Montserrat", Helvetica;
    font-size: 1rem;
    font-weight: 700;
    color: #000000;
    padding: 0.3125rem 0.625rem;
    border-radius: 0.5rem;
    margin-bottom: 0.625rem;
    display: inline-block;
}

.points-notification {
    position: absolute;
    display: inline-flex;
    align-items: center;
    z-index: 1;
    top: -6.5rem;
    left: 50%;
    transform: translateX(-50%);
}

.star-icon {
    width: 4.0625rem;
    height: 4.0625rem;
}

.points-text {
    position: absolute;
    font-family: "Montserrat", Helvetica;
    font-weight: 700;
    font-size: 1.25rem;
    color: #000;
    text-align: center;
    line-height: 2rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.webcam-container {
    margin-top: 10px;
    position: relative;
}

.webcam-container video {
    width: 100%;
    height: auto;
    border: 1px solid #ccc;
}

.webcam-container button {
    margin-top: 5px;
}

/* TABLET */
@media (max-width: 991px) {
    .ask-text {
        font-size: 1rem;
    }

    .earned-points {
        font-size: 0.875rem;
    }

    .student-icon {
        width: 4rem;
        height: 4rem;
    }

    .star-icon {
        width: 3.5rem;
        height: 3.5rem;
    }
}

/* MOBILE (HORIZONTAL) */
@media (max-width: 767px) {
    .ask-content {
        padding: 0.125rem;
    }

    .ask-text {
        font-size: 0.95rem;
    }

    .earned-points {
        font-size: 0.75rem;
    }

    .student-icon {
        width: 3.5rem;
        height: 3.5rem;
    }

    .star-icon {
        width: 3rem;
        height: 3rem;
    }
}

/* MOBILE (VERTICAL) */
@media (max-width: 478px) {
    .ask-content {
        padding: 0.125rem;
    }

    .ask-text {
        font-size: 0.875rem;
    }

    .earned-points {
        font-size: 0.6875rem;
    }

    .student-icon {
        width: 3rem;
        height: 3rem;
    }

    .star-icon {
        width: 2.5rem;
        height: 2.5rem;
    }
}

/* DESKTOP (DEFAULT) */
.frame {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 0.5rem;
    overflow: hidden;
}

.main-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.profile-content {
    display: flex;
    flex-direction: row;
    background-color: #d9d9d9;
    margin: 0.25rem;
    padding: 0.25rem;
    width: 100%;
}

/* AVATAR */
.avatar-content {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    height: 100vh;

    min-width: 45%;
}

.edit-avatar {
    display: flex;
    flex-direction: column;

    align-items: center;
    padding: 2rem;
}

.edit-button {
    font-family: "Montserrat", Helvetica;
    font-size: 0.825rem;

    padding: 0.5rem 1rem;
    border: none;
    border-radius: 3rem;

    cursor: pointer;
}

.names {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.name {
    font-family: "Montserrat", Helvetica;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.titles {
    display: flex;
    flex-direction: row;

    align-items: center;

    gap: 0.5rem;
    margin: 0;
    padding: 0;
}

.badge {
    height: 2rem;
    width: auto;
}

.title {
    font-family: "Montserrat", Helvetica;
    font-size: 1.25rem;
    font-weight: 300;

    font-style: italic;
    margin: 0;
}

.number-questions {
    font-family: "Montserrat", Helvetica;
    font-size: 2rem;
    font-weight: 900;

    margin-top: 2.5rem;
}

.description-questions {
    font-family: "Montserrat", Helvetica;
    font-size: 0.75rem;
    font-weight: 100;

    margin-top: 0.25rem;
}

/* CUSTOMIZATIONS */
.customize-content {
    display: flex;
    flex-direction: column;

    justify-content: space-between;

    height: 100%;
    overflow: hidden;
}

.collection {
    background-color: white;
    padding: 1rem;

    height: 32.5%;
    padding: 0.5rem;

    display: flex;
    flex-direction: column;

    min-width: 55%;
    overflow: hidden;
}

.customize-scrollable {
    display: flex;
    overflow-x: auto;

    gap: 0.5rem;
    flex-grow: 1;
}

.badge-item,
.title-item,
.accessory-item {
    width: 10rem;
    flex-shrink: 0;
    height: 100%;

    background-color: lightgray;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;

    text-align: center;
}

.customize {
    font-family: "Montserrat", Helvetica;
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.25rem;
}

/* TABLET */
@media (max-width: 991px) {
    img {
        width: 70%;
        height: auto;
    }
}

/* MOBILE (HORIZONTAL) */
@media (max-width: 767px) {
    .profile-content {
        flex-direction: column;
    }

    /* AVATAR */
    .avatar-content {
        width: 100%;
    }

    /* CUSTOMIZATIONS */
    .customize-content {
        height: 100%;
        width: 100%;
    }

    img {
        width: 50%;
        height: auto;
    }
}

/* MOBILE (VERTICAL) */
@media (max-width: 478px) {
    img {
        width: 40%;
        height: auto;
    }
}

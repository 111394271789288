.loader {
    display: flex;
    flex-direction: row;

    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;

    gap: 0.75rem;

    background-color: #c7c7c7;
    padding: 1rem;

    width: 7.5rem;
    height: 3rem;
}

.dot {
    width: 0.75rem;
    height: 0.75rem;

    background-color: #414141;
    border-radius: 50%;

    animation: bounce 0.5s infinite alternate;
}

.dot:nth-child(1) {
    background-color: #b1b1b1;
}

.dot:nth-child(2) {
    background-color: #939393;
    animation-delay: 100ms;
}

.dot:nth-child(3) {
    background-color: #7c7c7c;
    animation-delay: 200ms;
}

.dot:nth-child(4) {
    background-color: #777777;
    animation-delay: 300ms;
}

@keyframes bounce {
    100% {
        translate: 0 -0.75rem;
    }
}

/* DESKTOP (DEFAULT) */
.frame {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 0.5rem;
    overflow: hidden;
}

.main-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.content-header {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: flex-start;
}

.conversations-content {
    display: flex;
    flex-direction: column;
    background-color: #d9d9d9;

    margin: 0.25rem;
    padding: 1.75rem;

    width: 100%;
}

.conversations-name {
    font-family: "Montserrat", Helvetica;
    font-size: 2rem;
    font-weight: 700;

    margin-bottom: 2rem;
}

.conversations-content .student-content {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    margin-right: 1rem;
    gap: 1rem;
}

.student-content .profile-picture-content {
    margin-right: 1rem;
}

/* ACTUAL CONVERSATIONS */
.conversations-wrapper {
    position: relative;
    display: flex;

    border-radius: 0.5rem;
    overflow: hidden;

    background-color: #f9f9f9;
    height: 35rem;

    padding: 5rem 0;
    margin: 2.5rem 0;
}

.conversation-wrapper {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-items: center;

    width: 100%;
    margin: 0 2.5rem;
}

.conversation-content .chatbox {
    display: flex;
    flex-direction: column;

    gap: 0.625rem;
    max-height: 25rem;

    width: 100%;
    overflow-y: auto;
    padding: 0.825rem;

    border: 0.125rem solid #efca08;

    border-radius: 0.25rem;
    flex-grow: 1;
}

.message {
    margin-bottom: 0.825rem;
}

.text {
    margin-left: 0.825rem;
}

.slider {
    display: flex;
    align-items: center;
    padding-left: 1rem;

    height: 100%;

    writing-mode: vertical-lr;
    direction: ltr;

    vertical-align: top;
}

.slider input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    height: 100%;

    background: #fff;
    border: 2px solid #ddd;

    outline: none;
    margin: 0;
    padding: 0;

    border-radius: 0.5rem;
    writing-mode: bt-lr;
}

.slider input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;

    width: 2rem;
    height: 2rem;

    background: #efca08;
    cursor: pointer;

    border-radius: 50%;
}

.slider input[type="range"]::-moz-range-thumb {
    width: 2rem;
    height: 2rem;

    background: #efca08;

    cursor: pointer;
    border-radius: 50%;
}

.special-marker {
    position: absolute;

    width: 0.75rem;
    height: 0.75rem;
    background-color: #efca08;

    border-radius: 50%;
    cursor: pointer;
}

/* MARQUEE */
/* Top and bottom marquees share similar styles but move in different directions */
.marquee-top,
.marquee-bottom {
    font-family: "Montserrat", Helvetica;
    font-size: 1rem;
    font-weight: 100;

    position: absolute;
    width: 100%;

    white-space: nowrap;
    overflow: hidden;
    font-size: 16px;
    background-color: white;

    padding: 10px 0;
    color: #333;
}

/* Top marquee specific styles */
.marquee-top {
    top: 0;
    border-bottom: 2px solid #efca08;
}

/* Bottom marquee specific styles */
.marquee-bottom {
    bottom: 0;
    border-top: 2px solid #efca08;
}

/* Top marquee animation - Left to Right */
@keyframes marqueeTop {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(-100%);
    }
}

/* Bottom marquee animation - Right to Left */
@keyframes marqueeBottom {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(100%);
    }
}

/* Apply animation to top and bottom p tags */
.marquee-top p {
    animation: marqueeTop 20s linear infinite;
}

.marquee-bottom p {
    animation: marqueeBottom 20s linear infinite;
}

/* TABLET */
@media (max-width: 991px) {
    .conversations-name {
        font-size: 1.75rem;
    }
}

/* MOBILE (HORIZONTAL) */
@media (max-width: 767px) {
    .conversations-name {
        font-size: 1.5rem;
    }
}

/* MOBILE (VERTICAL) */
@media (max-width: 478px) {
    .conversations-name {
        font-size: 1.25rem;
    }
}

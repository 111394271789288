.star {
    margin: 0;
    padding: 0;

    font-size: 30px;
    color: #e4e5e9;
}

.earned-star {
    color: #f16100;
    animation: grow 0.5s ease, spark 1s ease-in-out;
}

/* Grow Animation */
@keyframes grow {
    0% {
        transform: scale(0.5);
    }
    20% {
        transform: scale(0.75);
    }
    40% {
        transform: scale(1.125);
    }
    60% {
        transform: scale(1.5);
    }
    80% {
        transform: scale(1.125);
    }
    100% {
        transform: scale(1);
    }
}

/* Spark Animation */
@keyframes spark {
    0% {
        filter: brightness(1);
    }
    20% {
        filter: brightness(1.2);
    }
    40% {
        filter: brightness(1.5);
    }
    60% {
        filter: brightness(1.7);
    }
    80% {
        filter: brightness(1.5);
    }
    100% {
        filter: brightness(1.2);
    }
}

/* TABLET */
@media (max-width: 991px) {
    .star {
        font-size: 30px;
    }
}

/* MOBILE (HORIZONTAL) */
@media (max-width: 767px) {
    .star {
        font-size: 25px;
    }
}

/* MOBILE (VERTICAL) */
@media (max-width: 478px) {
    .star {
        font-size: 20px;
    }
}

/* DESKTOP (DEFAULT) */
.student-item {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    font-size: 1.25rem;
    padding: 0.75rem;

    background-color: white;
    margin-bottom: 1rem;

    cursor: pointer;
}

.student-content {
    display: flex;
    flex-direction: row;

    align-items: center;

    margin-right: 1rem;
    gap: 1rem;
}

.profile-picture-content {
    width: 5rem;
    height: 5rem;
    overflow: hidden;

    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-picture {
    width: 100%;
    height: auto;

    object-fit: cover;
}

.status-content {
    display: flex;
    flex-direction: column;

    align-items: flex-start;
    gap: 0.75rem;
}

.name-content {
    display: flex;
    flex-direction: row;

    align-items: center;

    gap: 0.5rem;
}

.student-name {
    font-family: "Montserrat", Helvetica;
    font-size: 1.75rem;
    font-weight: 900;

    margin: 0;
    padding: 0;
}

.engagement {
    width: 1.75rem;
    height: 1.75rem;
}

.active {
    font-family: "Montserrat", Helvetica;
    font-size: 1rem;
    font-weight: 100;
}

.alert-content {
    display: flex;
    flex-direction: row;

    align-items: center;
}

.alert {
    width: 2.5rem;
    height: 2.5rem;
}

.carrot-profile {
    width: 3rem;
    height: 3rem;

    cursor: pointer;
}

/* TABLET */
@media (max-width: 991px) {
    .student-name {
        font-size: 1.5rem;
    }
}

/* MOBILE (HORIZONTAL) */
@media (max-width: 767px) {
    .profile-picture-content {
        width: 4rem;
        height: 4rem;
    }

    .student-content {
        gap: 0.75rem;
    }

    .student-name {
        font-size: 1.25rem;
    }

    .active {
        font-size: 0.75rem;
    }
}

/* MOBILE (VERTICAL) */
@media (max-width: 478px) {
    .student-name {
        font-size: 1.25rem;
    }
}

/* DESKTOP (DEFAULT) */
.frame {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 0.5rem;
    overflow: hidden;
}

.main-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.content-header {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: flex-start;
}

.students-content {
    display: flex;
    flex-direction: column;
    background-color: #d9d9d9;
    margin: 0.25rem;
    padding: 1.75rem;
    width: 100%;
}

.students-name {
    font-family: "Montserrat", Helvetica;
    font-size: 2rem;
    font-weight: 700;

    margin-bottom: 2rem;
}

.search-bar {
    width: 100%;
    padding: 0.5rem 1rem;

    border: none;
    border-radius: 3rem;

    margin-bottom: 1rem;
    font-size: 1rem;
}

.students-list {
    display: flex;
    flex-direction: column;

    flex-wrap: nowrap;
    overflow-x: auto;

    width: 100%;

    padding: 0;
    margin: 0;
}

/* TABLET */
@media (max-width: 991px) {
    .students-name {
        font-size: 1.75rem;
    }
}

/* MOBILE (HORIZONTAL) */
@media (max-width: 767px) {
    .students-name {
        font-size: 1.5rem;
    }

    .students-content {
        padding: 1.5rem;
    }
}

/* MOBILE (VERTICAL) */
@media (max-width: 478px) {
    .students-name {
        font-size: 1.25rem;
    }

    .students-content {
        padding: 1.25rem;
    }
}

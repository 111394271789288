/* DESKTOP (DEFAULT) */
.frame {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 0.5rem;
    overflow: hidden;
}

.main-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.screen-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #d9d9d9;
    margin: 0.25rem;
    padding: 0.25rem;
    width: 100%;
}

.dynamic-content {
    display: flex;
    flex-direction: column;

    justify-content: center;
    position: relative;

    height: 100%;
    overflow: hidden;
}

/* TABLET */
@media (max-width: 991px) {
    .dynamic-content {
        padding: 1rem;
    }
}

/* MOBILE (HORIZONTAL) */
@media (max-width: 767px) {
    .dynamic-content {
        padding: 0.75rem;
    }
}

/* MOBILE (VERTICAL) */
@media (max-width: 478px) {
    .dynamic-content {
        padding: 0.5rem;
    }
}

/* DESKTOP (DEFAULT) */
.chat-message-container {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    justify-content: flex-end;
}

.chat-message-container img {
    max-width: 100%;
    height: auto;
    border-radius: 0.5rem;
    margin: 0.3125rem 0;
}

.chat-message strong {
    font-weight: bold;
}

.chat-message {
    font-family: var(--m3-label-medium-font-family);
    font-size: 1.125rem;
    font-weight: var(--m3-label-medium-font-weight);
    background-color: #ffffff;
    color: #000000;
    padding: 0.625rem;
    border-radius: 0.5rem;
    max-width: 65%;
    margin: 0.3125rem 0;

    /* for large texts */
    /* overflow-wrap: break-word; */
    /* word-wrap: break-word; */
    /* white-space: normal; */
    /* overflow: hidden; */
    /* hyphens: auto; */

    animation: growMessage 0.8s cubic-bezier(0.25, 1, 0.5, 1);
}

@keyframes growMessage {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    60% {
        transform: scale(1.05);
        opacity: 1;
    }
    80% {
        transform: scale(0.98);
    }
    100% {
        transform: scale(1);
    }
}

.is-special {
    background-color: rgba(255, 255, 0, 0.1);
}

.user {
    margin-left: auto;
    text-align: right;
    display: flex;
    align-items: center;
    gap: 0.625rem;

    transform-origin: bottom right;
}

.response {
    margin-right: auto;
    text-align: left;
    gap: 0.625rem;

    transform-origin: bottom left;
}

.response .teacher-icon-in-chat {
    order: -1;
}

.uploaded-image {
    width: 18.75rem;
    height: auto;
}

.teacher-icon-in-chat {
    width: 3rem;
    height: auto;
}

/* TABLET */
@media (max-width: 991px) {
    .chat-message {
        font-size: 1rem;
        max-width: 75%;
    }

    .uploaded-image {
        width: 15rem;
    }

    .teacher-icon-in-chat {
        width: 2.75rem;
        height: auto;
    }
}

/* MOBILE (HORIZONTAL) */
@media (max-width: 767px) {
    .chat-message {
        font-size: 0.95rem;
        max-width: 80%;
    }

    .uploaded-image {
        width: 12rem;
    }

    .teacher-icon-in-chat {
        width: 2.5rem;
        height: auto;
    }
}

/* MOBILE (VERTICAL) */
@media (max-width: 478px) {
    .chat-message {
        font-size: 0.875rem;
        max-width: 85%;
    }

    .uploaded-image {
        width: 10rem;
    }

    .teacher-icon-in-chat {
        width: 2.25rem;
        height: auto;
    }
}

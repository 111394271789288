/* DESKTOP (DEFAULT) */
.chatbox {
    display: flex;
    flex-direction: column;

    gap: 0.625rem;
    max-height: 100%;

    overflow-y: auto;
    padding: 0.625rem;

    border-radius: 0.5rem;
    flex-grow: 1;
}

/* TABLET */
@media (max-width: 991px) {
}

/* MOBILE (HORIZONTAL) */
@media (max-width: 767px) {
}

/* MOBILE (VERTICAL) */
@media (max-width: 478px) {
}

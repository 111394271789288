/* DESKTOP (DEFAULT) */
.progressbar-wrapper {
    /* display: flex; */
    align-items: center;
    width: 100%;
    height: 0.9375rem;
}

.progressbar-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border: 0.125rem solid #ffffff;
    border-radius: 0.625rem;
    overflow: hidden;
}

.progressbar-fill {
    height: 100%;
    background-color: #efca08;
    width: 0;
    transition: width 0.5s ease;
    border-radius: 0.625rem;
}

.progressbar-percentage {
    font-family: "Montserrat", Helvetica;
    margin-left: 0.625rem;
    font-size: 0.875rem;
    font-weight: bold;
    color: #000000;
}

/* TABLET */
@media (max-width: 991px) {
}

/* MOBILE (HORIZONTAL) */
@media (max-width: 767px) {
}

/* MOBILE (VERTICAL) */
@media (max-width: 478px) {
}

/* DESKTOP (DEFAULT) */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(130, 130, 130, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.popup {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 2rem;
    margin: 2rem;
    border-radius: 0.5rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.popup h1 {
    font-family: "Montserrat", Helvetica;
    font-size: 2rem;
    margin-bottom: 1.5rem;
}

/* LEVEL (REWARDS) POPUP */
.rewards {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.reward-section,
.encouragement-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 12.5rem;
    word-wrap: break-word;
}

.reward-title,
.encouragement-title,
.encouragement-detail {
    font-family: "Montserrat", Helvetica;
    font-size: 0.825rem;
    text-align: center;
    white-space: normal;
    margin-bottom: 1rem;
}

button {
    font-family: "Montserrat", Helvetica;
    font-size: 0.825rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 3rem;
    margin-top: 1rem;
    cursor: pointer;
}

.encouragement-detail {
    font-family: "Montserrat", Helvetica;
    font-size: 0.75rem;
}

.reward-section img,
.encouragement-section img {
    height: 60%;
}

.close-button {
    font-family: "Montserrat", Helvetica;
    font-size: 0.825rem;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.5rem;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.close-button:hover {
    background-color: #0056b3;
}

.encouragement-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 200px;
    position: relative;
    text-align: center;
    word-wrap: break-word;
}

.encouragement-title,
.encouragement-detail {
    position: relative;
    z-index: 5;
}

.star1,
.star2 {
    position: absolute;
    opacity: 0.75;
    z-index: 4;
}

.star1 {
    top: 0;
    right: 0;
}

.star2 {
    bottom: -1rem;
    left: -1rem;
    transform: rotate(180deg) scale(0.75, 0.75);
}

/* CAMERA POPUP */
.photo-buttons {
    display: flex;
    flex-direction: column;

    justify-content: center;
    margin-bottom: 1rem;
}

.preview-buttons {
    display: flex;
    flex-direction: row;

    gap: 0.5rem;
    justify-content: center;
}

.retake-button,
.confirm-button {
    width: 100%;
}

.confirm-button {
    background-color: #007bff;
    color: white;
}

.confirm-button:hover {
    background-color: #0056b3;
}

.camera-feed {
    width: 100%;
    height: auto;
}

.photo-preview {
    width: 100%;
    height: auto;
}

.captured-photo {
    width: 100%;
    height: auto;
}

/* LOGIN POPUP */
.login-header {
    display: flex;
    flex-direction: column;

    align-items: center;
}

.collabria-full-logo {
    width: 27.5rem;
    height: auto;

    margin-bottom: 2.5rem;
}

.login-content {
    display: flex;
    flex-direction: column;

    padding: 1rem;

    height: 22.5rem;
    max-width: 50rem;

    overflow: hidden;
}

.login-content img {
    width: 10rem;
    height: auto;

    gap: 1rem;
    padding: 0.5rem;

    border-radius: 0.5rem;

    cursor: pointer;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.login-content img:hover {
    transform: scale(1.1);
    opacity: 0.85;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.signin-content,
.create-account-content {
    display: flex;
    flex-direction: column;

    align-items: center;
    width: 100%;
}

.login-info {
    display: flex;
    flex-direction: column;

    align-items: center;
    width: 100%;
}

.login-info input {
    padding: 0.75rem 1rem;

    width: 100%;
    max-width: 18.75rem;

    border: 1px solid #ccc;

    border-radius: 0.5rem;
    font-size: 1rem;

    background-color: #f9f9f9;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);

    transition: border-color 0.3s, box-shadow 0.3s;
}

.login-info input:focus {
    border-color: #efca08;
    box-shadow: 0 0 5px rgba(190, 163, 11, 0.681);

    background-color: #fff;
    outline: none;
}

.login-content button {
    background-color: #efca08;
    color: white;

    border: none;
    cursor: pointer;

    margin: 0;
}

.login-content button:hover {
    background-color: #bd9f07;
}

.toggle-option {
    font-family: "Montserrat", Helvetica;
    font-size: 0.825rem;
    margin-top: 2rem;
}

.toggle-option a {
    color: #bd9f07;
}

.input-content {
    width: 100%;
    margin-bottom: 1.5rem;
}

.input-content .input-error {
    border: 1px solid red;
    box-shadow: 0 0 8px rgba(255, 0, 0, 0.5);
}

.error-text {
    font-family: "Montserrat", Helvetica;
    font-size: 0.75rem;
    color: red;

    margin-top: 0.5rem;
}

/* EDIT AVATAR POPUP */
.edit-avatar-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
    max-width: 50rem;

    overflow: hidden;
}

.all-avatars {
    background-color: white;

    padding: 1rem;
    height: 15rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    min-width: 55%;
}

.customize-scrollable {
    display: flex;
    overflow-x: auto;
    gap: 0.5rem;
    flex-grow: 1;
    scroll-behavior: smooth;
    height: 100%;
}

.customize-scrollable::-webkit-scrollbar {
    height: 8px;
}

.customize-scrollable::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 10px;
}

.avatar-item {
    width: 10rem;
    flex-shrink: 0;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.avatar-item img {
    width: 100%;
    height: auto;

    gap: 1rem;
    padding: 0.5rem;
    margin: 0.5rem;

    border-radius: 0.5rem;

    cursor: pointer;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.avatar-item img:hover {
    transform: scale(1.1);
    opacity: 0.85;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* TABLET */
@media (max-width: 991px) {
    img {
        height: 90%;
    }

    .popup {
        padding: 1.5rem;
        margin: 1.5rem;
    }

    .popup h1 {
        font-size: 1.5rem;
    }

    .reward-title,
    .encouragement-title,
    .encouragement-detail {
        font-size: 0.75rem;
    }

    .reward-button,
    .close-button {
        font-size: 0.75rem;
    }

    .edit-avatar-content {
        max-width: 40rem;
    }
}

/* MOBILE (HORIZONTAL) */
@media (max-width: 767px) {
    .collabria-full-logo {
        width: 22.5rem;
    }

    img {
        width: 80%;
    }

    .popup {
        padding: 1rem;
        margin: 1rem;
    }

    .popup h1 {
        font-size: 1.25rem;
    }

    .reward-section,
    .encouragement-section {
        width: 8rem;
    }

    .reward-title,
    .encouragement-title,
    .encouragement-detail {
        font-size: 0.625rem;
    }

    .reward-button,
    .close-button {
        font-size: 0.625rem;
    }

    .edit-avatar-content {
        max-width: 25rem;
    }
}

/* MOBILE (VERTICAL) */
@media (max-width: 478px) {
    .collabria-full-logo {
        width: 20rem;
    }

    .rewards {
        flex-direction: column;
    }

    .popup {
        padding: 0.5rem;
        margin: 0.5rem;
    }

    .popup h1 {
        font-size: 1rem;
    }

    .reward-section,
    .encouragement-section {
        width: 12.5rem;
    }

    .reward-title,
    .encouragement-title,
    .encouragement-detail {
        font-size: 0.5rem;
    }

    .reward-button,
    .close-button {
        font-size: 0.5rem;
    }

    .edit-avatar-content {
        max-width: 20rem;
    }
}

/* DESKTOP (DEFAULT) */
.levelup-content {
    display: flex;
    flex-direction: column;

    background-color: white;
    padding: 0.75rem;
    margin: 0.5rem;

    border-radius: 0.5rem;
}

.levelup-score {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;

    margin-bottom: 0.5rem;
}

.levelup-percentage {
    font-family: "Montserrat", Helvetica;
    font-size: 1.25rem;
    font-weight: bold;

    color: #000000;
}

.stars {
    display: flex;
    flex-direction: row;
}

.levelup-wrapper {
    display: flex;
    align-items: center;

    width: 30rem;
    height: 1.5rem;

    margin-top: 0.25rem;
}

.levelup-container {
    position: relative;
    width: 100%;
    height: 100%;

    background-color: #006bb9;
    border: 0.25rem solid #0b77c2;

    border-radius: 1.25rem;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.375);

    /* overflow: hidden; */
}

.levelup-fill {
    height: 100%;
    background-image: linear-gradient(90deg, #fdc972, #ff9e2c);
    box-shadow: 0px 2 5px rgba(243, 94, 3, 0.6);

    width: 0;

    transition: width 0.5s ease;
    border-radius: 0.625rem;
}

/* TABLET */
@media (max-width: 991px) {
    .levelup-percentage {
        font-size: 1.125rem;
    }

    .levelup-wrapper {
        width: 27.5rem;
        height: 1.25rem;
    }
}

/* MOBILE (HORIZONTAL) */
@media (max-width: 767px) {
    .levelup-percentage {
        font-size: 1rem;
    }

    .levelup-wrapper {
        width: 25rem;
        height: 1.125rem;
    }
}

/* MOBILE (VERTICAL) */
@media (max-width: 478px) {
    .levelup-percentage {
        font-size: 1rem;
    }

    .levelup-wrapper {
        width: 20rem;
        height: 1rem;
    }
}

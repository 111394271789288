/* DESKTOP (DEFAULT) */
.menu-content {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: space-between;
}

.upper-content,
.lower-content {
    display: flex;
    flex-direction: column;

    align-items: center;

    gap: 0.25rem;
    margin: 0.25rem;
    margin-left: 0;
}

.icon {
    width: 2.75rem;
    height: 2.75rem;

    cursor: pointer;
}

.icon.assignment {
    margin-left: 0.5rem;
}

/* TABLET */
@media (max-width: 991px) {
    .collabria {
        width: 2.75rem;
        height: 2.75rem;
    }

    .icon {
        width: 2.5rem;
        height: 2.5rem;
    }
}

/* MOBILE (HORIZONTAL) */
@media (max-width: 767px) {
    .collabria {
        width: 2.5rem;
        height: 2.5rem;
    }

    .icon {
        width: 2.25rem;
        height: 2.25rem;
    }
}

/* MOBILE (VERTICAL) */
@media (max-width: 478px) {
    .collabria {
        width: 2.25rem;
        height: 2.25rem;
    }

    .icon {
        width: 2rem;
        height: 2rem;
    }
}

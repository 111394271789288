/* DESKTOP (DEFAULT) */
.frame {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 0.5rem;
    overflow: hidden;
}

.main-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.upload-assignments-content {
    display: flex;
    flex-direction: column;
    background-color: #d9d9d9;
    margin: 0.25rem;
    padding: 1.75rem;
    width: 100%;
}

.content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.upload-assignments-name {
    font-family: "Montserrat", Helvetica;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
}

.upload-container {
    display: flex;
    flex-direction: row;

    justify-content: space-between;

    height: 100%;
    width: 100%;
}

.uploaded-docs {
    display: flex;
    flex-direction: column;

    width: 35%;
    margin-right: 1rem;
}

.documents {
    font-family: "Montserrat", Helvetica;
    font-size: 1.5rem;
    font-weight: 700;

    margin: 0;
    padding: 0;

    margin-bottom: 1.25rem;
}

.documents-list {
    display: flex;
    flex-direction: column;

    overflow-y: auto;
    overflow-x: hidden;

    max-height: 35rem;
    width: 100%;

    padding: 0;
    margin: 0;
}

.document-item {
    display: flex;
    flex-direction: row;

    background-color: white;
    border-radius: 0.5rem;

    align-items: center;
    width: 100%;
    height: 7rem;

    margin-bottom: 1.25rem;
    padding: 1rem;

    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.drag-drop-item {
    display: flex;
    flex-direction: row;

    background-color: white;
    border-radius: 0.5rem;

    align-items: center;
    width: 100%;
    height: 5rem;

    margin: 0.5rem 0;
    padding: 1rem;

    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.document-item:hover {
    background-color: rgba(255, 255, 0, 0.1);
    box-shadow: 0 0 10px rgba(255, 255, 0, 0.5);
}

.document-icon img {
    width: 3rem;
    height: 3rem;

    margin-right: 1rem;
}

.document-info {
    width: 100%;
}

.document-info p {
    font-family: "Montserrat", Helvetica;
    font-size: 1rem;
    font-weight: 100;

    width: 100%;
    cursor: pointer;
}

.document-info input {
    font-family: "Montserrat", Helvetica;
    font-size: 0.825rem;
    font-weight: 100;

    width: 100%;
    padding: 0.25rem;
}

.document-details {
    margin-bottom: 1rem;
}

.date {
    font-family: "Montserrat", Helvetica;
    font-size: 0.75rem;
    font-weight: 100;

    width: 100%;
}

.file-upload-area {
    height: 100%;
    width: 100%;

    padding: 1rem;
}

.drag-drop-area {
    display: flex;
    flex-direction: column;

    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;

    border: 2px dashed #ccc;
    padding: 2rem;
    gap: 0.5rem;

    text-align: center;
    transition: border 0.3s ease;

    margin-bottom: 1.25rem;
    height: 100%;
    width: 100%;
}

.drag-drop-area:hover {
    border: 2px solid #efca08;
    background-color: rgba(239, 230, 109, 0.1);
}

.drag-drop {
    font-family: "Montserrat", Helvetica;
    font-size: 1rem;
    font-weight: 300;

    margin: 0.75rem;
}

.drag-drog-buttons {
    display: flex;
    flex-direction: column;

    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    text-align: center;

    margin: 0.75rem;
    gap: 0.5rem;
}

.drag-drop-button {
    font-family: "Montserrat", Helvetica;
    font-size: 0.825rem;
    font-weight: 300;

    margin: 0;

    padding: 0.5rem 1rem;
    border: none;
    border-radius: 3rem;
    /* width: 15%; */

    cursor: pointer;
}

.drag-drop-list-item {
    display: flex;
    border-radius: 0.5rem;

    align-items: center;
    justify-content: center;

    width: 100%;
    height: auto;

    margin: 1rem 0;
    padding: 2rem;

    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    border: 2px dashed #ccc;

    text-align: center;
    transition: border 0.3s ease;
}

.drag-drop-list-item:hover {
    border: 2px solid #efca08;
    background-color: rgba(239, 230, 109, 0.1);
}

.documents-loading {
    margin-bottom: 0.75rem;
}

.document-content {
    display: flex;
    flex-direction: column;

    gap: 1rem;
    padding: 1rem;

    width: 60%;
    transition: all 0.3s ease;
}

.document-preview {
    justify-content: center;
    align-items: center;

    padding: 0.5rem;
    background-color: #f9f9f9;

    width: 100%;
    height: 50vh;

    overflow-y: auto;
}

.uploaded-preview {
    width: 100%;
    height: auto;

    max-height: none;

    object-fit: cover;
    overflow-y: auto;
}

.document-preview .react-pdf__Document {
    width: 100%;
    height: auto;
}

.questions-container {
    display: flex;
    flex-direction: column;

    overflow-y: auto;
    overflow-x: hidden;

    max-height: 20rem;
    width: 100%;

    padding: 0;
    margin: 0;
}

textarea {
    resize: vertical;
    padding: 0.5rem;

    border: 1px solid #ccc;
    border-radius: 0.25rem;

    font-size: 1rem;
    width: 100%;
    height: 50%;
}

/* TABLET */
@media (max-width: 991px) {
    .upload-assignments-name {
        font-size: 1.75rem;
    }

    .documents-list {
        max-height: 32.5rem;
    }
}

/* MOBILE (HORIZONTAL) */
@media (max-width: 767px) {
    .upload-assignments-name {
        font-size: 1.5rem;
    }

    .upload-assignments-content {
        padding: 1.5rem;
    }
}

/* MOBILE (VERTICAL) */
@media (max-width: 478px) {
    .upload-assignments-name {
        font-size: 1.25rem;
    }

    .upload-assignments-content {
        padding: 1.25rem;
    }
}

/* DESKTOP (DEFAULT) */

/* PROFILE */
.avatar {
    display: flex;
    flex-direction: column;

    justify-content: center;

    align-items: center;
    position: relative;
}

.avatar .body {
    width: 15rem;
    height: auto;
}

.avatar .head {
    position: absolute;
    transform: rotate(20deg);

    width: 8rem;
    height: auto;

    top: -5rem;
    left: 6rem;
}

/* ICON */
.avatar-icon {
    display: flex;
    flex-direction: column;

    justify-content: center;

    align-items: center;
    position: relative;
}

.avatar-icon .body {
    width: 5rem;
    height: auto;
}

.avatar-icon .head {
    position: absolute;
    transform: rotate(20deg);

    width: 2.5rem;
    height: auto;

    top: -1.5rem;
    left: 2rem;
}

/* CHAT */
.avatar-chat {
    display: flex;
    flex-direction: column;

    justify-content: center;

    align-items: center;
    position: relative;
}

.avatar-chat .body {
    width: 3rem;
    height: auto;
}

.avatar-chat .head {
    position: absolute;
    transform: rotate(20deg);

    width: 1.5rem;
    height: auto;

    top: -0.75rem;
    left: 1.25rem;
}

/* POPUP */
.avatar-popup {
    display: flex;
    flex-direction: column;

    justify-content: center;

    align-items: center;
    position: relative;
}

.avatar-popup .body {
    width: 20rem;
    height: auto;
}

.avatar-popup .head {
    position: absolute;
    transform: rotate(20deg);

    width: 10rem;
    height: auto;

    top: -5rem;
    left: 9rem;
}

.has-hat {
    margin-top: 6rem;
}

button {
    margin-top: 0.625rem;
}

/* TABLET */
@media (max-width: 991px) {
    /* PROFILE */
    .avatar .body {
        width: 12rem;
    }

    .avatar .head {
        width: 6rem;

        top: -3rem;
        left: 5.5rem;
    }

    /* ICON */
    .avatar-icon .body {
        width: 4rem;
    }

    .avatar-icon .head {
        width: 2rem;

        top: -1rem;
        left: 1.75rem;
    }

    /* CHAT */
    .avatar-chat .body {
        width: 2.5rem;
    }

    .avatar-chat .head {
        width: 1.25rem;

        top: -0.75rem;
        left: 1rem;
    }

    /* POPUP */
    .avatar-popup .body {
        width: 16rem;
    }

    .avatar-popup .head {
        width: 8rem;

        top: -5rem;
        left: 7rem;
    }

    button {
        margin-top: 0.5rem;
    }
}

/* MOBILE (HORIZONTAL) */
@media (max-width: 767px) {
    /* PROFILE */
    .avatar .body {
        width: 8rem;
    }

    .avatar .head {
        width: 4rem;

        top: -2rem;
        left: 3.5rem;
    }

    /* ICON */
    .avatar-icon .body {
        width: 3.5rem;
    }

    .avatar-icon .head {
        width: 1.75rem;

        top: -1rem;
        left: 1.5rem;
    }

    /* CHAT */
    .avatar-chat .body {
        width: 2rem;
    }

    .avatar-chat .head {
        width: 1rem;

        top: -0.5rem;
        left: 0.85rem;
    }

    /* POPUP */
    .avatar-popup .body {
        width: 14rem;
    }

    .avatar-popup .head {
        width: 8rem;

        top: -5rem;
        left: 6rem;
    }

    button {
        margin-top: 0.4rem;
    }
}

/* MOBILE (VERTICAL) */
@media (max-width: 478px) {
    /* PROFILE */
    .avatar .body {
        width: 8rem;
    }

    .avatar .head {
        width: 4rem;

        top: -2rem;
        left: 3.5rem;
    }

    /* ICON */
    .avatar-icon .body {
        width: 3rem;
    }

    .avatar-icon .head {
        width: 1.5rem;

        top: -0.75rem;
        left: 1.25rem;
    }

    /* CHAT */
    .avatar-chat .body {
        width: 1.75rem;
    }

    .avatar-chat .head {
        width: 1rem;

        top: -0.5rem;
        left: 0.75rem;
    }

    /* POPUP */
    .avatar-popup .body {
        width: 12rem;
    }

    .avatar-popup .head {
        width: 6rem;

        top: -4rem;
        left: 5rem;
    }

    .has-hat {
        margin-top: 5rem;
    }

    button {
        margin-top: 0.3rem;
    }
}

.ask-button {
  cursor: pointer;
  border: none;
  border-radius: .5rem;
  margin: 0;
  padding: .5rem 1rem;
  font-family: Montserrat, Helvetica;
  font-size: .825rem;
}

.menu-content {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.upper-content, .lower-content {
  flex-direction: column;
  align-items: center;
  gap: .25rem;
  margin: .25rem .25rem .25rem 0;
  display: flex;
}

.icon {
  cursor: pointer;
  width: 2.75rem;
  height: 2.75rem;
}

.icon.assignment {
  margin-left: .5rem;
}

@media (width <= 991px) {
  .collabria {
    width: 2.75rem;
    height: 2.75rem;
  }

  .icon {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (width <= 767px) {
  .collabria {
    width: 2.5rem;
    height: 2.5rem;
  }

  .icon {
    width: 2.25rem;
    height: 2.25rem;
  }
}

@media (width <= 478px) {
  .collabria {
    width: 2.25rem;
    height: 2.25rem;
  }

  .icon {
    width: 2rem;
    height: 2rem;
  }
}

.classroom-content {
  background-color: #d9d9d9;
  flex-direction: column;
  width: 100%;
  margin: .25rem;
  padding: 1.75rem;
  display: flex;
}

.classroom-name {
  margin-bottom: 2rem;
  font-family: Montserrat, Helvetica;
  font-size: 2rem;
  font-weight: 700;
}

.quadrants {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.quadrants:before {
  content: "";
  z-index: 0;
  border-top: .125rem dotted #888;
  width: 100%;
  height: 0;
  position: absolute;
  top: 50%;
}

.quadrants:after {
  content: "";
  z-index: 0;
  border-left: .125rem dotted #888;
  width: 0;
  height: 100%;
  position: absolute;
  left: 50%;
}

.quadrant {
  opacity: .5;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  font-size: 3rem;
  display: flex;
  position: absolute;
}

.top-left {
  background-color: #ffebcc;
  top: 0;
  left: 0;
}

.top-right {
  background-color: #cfc;
  top: 0;
  right: 0;
}

.bottom-left {
  background-color: #ffcccb;
  bottom: 0;
  left: 0;
}

.bottom-right {
  background-color: #cef;
  bottom: 0;
  right: 0;
}

.emoji {
  font-size: 5rem;
}

.y-axis {
  z-index: 1;
  font-family: Montserrat, Helvetica;
  font-size: 1.5rem;
  font-weight: 600;
  position: absolute;
}

.y-axis.top {
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

.y-axis.bottom {
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

.x-axis {
  z-index: 1;
  font-family: Montserrat, Helvetica;
  font-size: 1.5rem;
  font-weight: 600;
  position: absolute;
}

.x-axis.left {
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
}

.x-axis.right {
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}

.student-profile {
  cursor: pointer;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  transform: translate(-50%, -50%);
}

.student-pic {
  object-fit: cover;
  border: .125rem solid #000;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  transition: transform .2s ease-in-out;
  box-shadow: 0 4px 6px #0000001a, 0 1px 3px #00000014;
}

.student-pic:hover {
  transform: scale(1.1);
  box-shadow: 0 8px 10px #0003, 0 4px 6px #00000026;
}

.student-profile .student-name {
  color: #333;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 8rem;
  margin-top: .25rem;
  font-size: .75rem;
  overflow: hidden;
}

@media (width <= 991px) {
  .classroom-name {
    font-size: 1.75rem;
  }

  .emoji {
    font-size: 5rem;
  }
}

@media (width <= 767px) {
  .classroom-name {
    font-size: 1.5rem;
  }

  .y-axis, .x-axis {
    font-size: 1.25rem;
  }

  .emoji {
    font-size: 4rem;
  }

  .student-pic {
    width: 3.5rem;
    height: 3.5rem;
  }
}

@media (width <= 478px) {
  .classroom-name {
    font-size: 1.25rem;
  }

  .y-axis, .x-axis {
    font-size: 1rem;
  }

  .emoji {
    font-size: 3rem;
  }

  .student-pic {
    width: 3.25rem;
    height: 3.25rem;
  }
}

.avatar {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.avatar .body {
  width: 15rem;
  height: auto;
}

.avatar .head {
  width: 8rem;
  height: auto;
  position: absolute;
  top: -5rem;
  left: 6rem;
  transform: rotate(20deg);
}

.avatar-icon {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.avatar-icon .body {
  width: 5rem;
  height: auto;
}

.avatar-icon .head {
  width: 2.5rem;
  height: auto;
  position: absolute;
  top: -1.5rem;
  left: 2rem;
  transform: rotate(20deg);
}

.avatar-chat {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.avatar-chat .body {
  width: 3rem;
  height: auto;
}

.avatar-chat .head {
  width: 1.5rem;
  height: auto;
  position: absolute;
  top: -.75rem;
  left: 1.25rem;
  transform: rotate(20deg);
}

.avatar-popup {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.avatar-popup .body {
  width: 20rem;
  height: auto;
}

.avatar-popup .head {
  width: 10rem;
  height: auto;
  position: absolute;
  top: -5rem;
  left: 9rem;
  transform: rotate(20deg);
}

.has-hat {
  margin-top: 6rem;
}

button {
  margin-top: .625rem;
}

@media (width <= 991px) {
  .avatar .body {
    width: 12rem;
  }

  .avatar .head {
    width: 6rem;
    top: -3rem;
    left: 5.5rem;
  }

  .avatar-icon .body {
    width: 4rem;
  }

  .avatar-icon .head {
    width: 2rem;
    top: -1rem;
    left: 1.75rem;
  }

  .avatar-chat .body {
    width: 2.5rem;
  }

  .avatar-chat .head {
    width: 1.25rem;
    top: -.75rem;
    left: 1rem;
  }

  .avatar-popup .body {
    width: 16rem;
  }

  .avatar-popup .head {
    width: 8rem;
    top: -5rem;
    left: 7rem;
  }

  button {
    margin-top: .5rem;
  }
}

@media (width <= 767px) {
  .avatar .body {
    width: 8rem;
  }

  .avatar .head {
    width: 4rem;
    top: -2rem;
    left: 3.5rem;
  }

  .avatar-icon .body {
    width: 3.5rem;
  }

  .avatar-icon .head {
    width: 1.75rem;
    top: -1rem;
    left: 1.5rem;
  }

  .avatar-chat .body {
    width: 2rem;
  }

  .avatar-chat .head {
    width: 1rem;
    top: -.5rem;
    left: .85rem;
  }

  .avatar-popup .body {
    width: 14rem;
  }

  .avatar-popup .head {
    width: 8rem;
    top: -5rem;
    left: 6rem;
  }

  button {
    margin-top: .4rem;
  }
}

@media (width <= 478px) {
  .avatar .body {
    width: 8rem;
  }

  .avatar .head {
    width: 4rem;
    top: -2rem;
    left: 3.5rem;
  }

  .avatar-icon .body {
    width: 3rem;
  }

  .avatar-icon .head {
    width: 1.5rem;
    top: -.75rem;
    left: 1.25rem;
  }

  .avatar-chat .body {
    width: 1.75rem;
  }

  .avatar-chat .head {
    width: 1rem;
    top: -.5rem;
    left: .75rem;
  }

  .avatar-popup .body {
    width: 12rem;
  }

  .avatar-popup .head {
    width: 6rem;
    top: -4rem;
    left: 5rem;
  }

  .has-hat {
    margin-top: 5rem;
  }

  button {
    margin-top: .3rem;
  }
}

.loader {
  background-color: #c7c7c7;
  flex-direction: row;
  place-content: center;
  place-items: center;
  gap: .75rem;
  width: 7.5rem;
  height: 3rem;
  padding: 1rem;
  display: flex;
}

.dot {
  background-color: #414141;
  border-radius: 50%;
  width: .75rem;
  height: .75rem;
  animation: .5s infinite alternate bounce;
}

.dot:first-child {
  background-color: #b1b1b1;
}

.dot:nth-child(2) {
  background-color: #939393;
  animation-delay: .1s;
}

.dot:nth-child(3) {
  background-color: #7c7c7c;
  animation-delay: .2s;
}

.dot:nth-child(4) {
  background-color: #777;
  animation-delay: .3s;
}

@keyframes bounce {
  100% {
    translate: 0 -.75rem;
  }
}

.chat-message-container {
  justify-content: flex-end;
  align-items: center;
  gap: .625rem;
  display: flex;
}

.chat-message-container img {
  border-radius: .5rem;
  max-width: 100%;
  height: auto;
  margin: .3125rem 0;
}

.chat-message strong {
  font-weight: bold;
}

.chat-message {
  font-family: var(--m3-label-medium-font-family);
  font-size: 1.125rem;
  font-weight: var(--m3-label-medium-font-weight);
  color: #000;
  background-color: #fff;
  border-radius: .5rem;
  max-width: 65%;
  margin: .3125rem 0;
  padding: .625rem;
  animation: .8s cubic-bezier(.25, 1, .5, 1) growMessage;
}

@keyframes growMessage {
  0% {
    opacity: 0;
    transform: scale(.5);
  }

  60% {
    opacity: 1;
    transform: scale(1.05);
  }

  80% {
    transform: scale(.98);
  }

  100% {
    transform: scale(1);
  }
}

.is-special {
  background-color: #ffff001a;
}

.user {
  text-align: right;
  transform-origin: 100% 100%;
  align-items: center;
  gap: .625rem;
  margin-left: auto;
  display: flex;
}

.response {
  text-align: left;
  transform-origin: 0 100%;
  gap: .625rem;
  margin-right: auto;
}

.response .teacher-icon-in-chat {
  order: -1;
}

.uploaded-image {
  width: 18.75rem;
  height: auto;
}

.teacher-icon-in-chat {
  width: 3rem;
  height: auto;
}

@media (width <= 991px) {
  .chat-message {
    max-width: 75%;
    font-size: 1rem;
  }

  .uploaded-image {
    width: 15rem;
  }

  .teacher-icon-in-chat {
    width: 2.75rem;
    height: auto;
  }
}

@media (width <= 767px) {
  .chat-message {
    max-width: 80%;
    font-size: .95rem;
  }

  .uploaded-image {
    width: 12rem;
  }

  .teacher-icon-in-chat {
    width: 2.5rem;
    height: auto;
  }
}

@media (width <= 478px) {
  .chat-message {
    max-width: 85%;
    font-size: .875rem;
  }

  .uploaded-image {
    width: 10rem;
  }

  .teacher-icon-in-chat {
    width: 2.25rem;
    height: auto;
  }
}

.chatbox {
  border-radius: .5rem;
  flex-direction: column;
  flex-grow: 1;
  gap: .625rem;
  max-height: 100%;
  padding: .625rem;
  display: flex;
  overflow-y: auto;
}

.popup-overlay {
  z-index: 3;
  background: #828282bf;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.popup {
  text-align: center;
  background: #fff;
  border-radius: .5rem;
  flex-direction: column;
  place-content: center;
  align-items: center;
  margin: 2rem;
  padding: 2rem;
  display: flex;
  box-shadow: 0 4px 10px #00000026;
}

.popup h1 {
  margin-bottom: 1.5rem;
  font-family: Montserrat, Helvetica;
  font-size: 2rem;
}

.rewards {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.reward-section, .encouragement-section {
  word-wrap: break-word;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12.5rem;
  padding: 1rem;
  display: flex;
}

.reward-title, .encouragement-title, .encouragement-detail {
  text-align: center;
  white-space: normal;
  margin-bottom: 1rem;
  font-family: Montserrat, Helvetica;
  font-size: .825rem;
}

button {
  cursor: pointer;
  border: none;
  border-radius: 3rem;
  margin-top: 1rem;
  padding: .5rem 1rem;
  font-family: Montserrat, Helvetica;
  font-size: .825rem;
}

.encouragement-detail {
  font-family: Montserrat, Helvetica;
  font-size: .75rem;
}

.reward-section img, .encouragement-section img {
  height: 60%;
}

.close-button {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  border-radius: .5rem;
  margin-top: 1rem;
  padding: .5rem 1rem;
  font-family: Montserrat, Helvetica;
  font-size: .825rem;
}

.close-button:hover {
  background-color: #0056b3;
}

.encouragement-section {
  text-align: center;
  word-wrap: break-word;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  padding: 1rem;
  display: flex;
  position: relative;
}

.encouragement-title, .encouragement-detail {
  z-index: 5;
  position: relative;
}

.star1, .star2 {
  opacity: .75;
  z-index: 4;
  position: absolute;
}

.star1 {
  top: 0;
  right: 0;
}

.star2 {
  bottom: -1rem;
  left: -1rem;
  transform: rotate(180deg)scale(.75);
}

.photo-buttons {
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
  display: flex;
}

.preview-buttons {
  flex-direction: row;
  justify-content: center;
  gap: .5rem;
  display: flex;
}

.retake-button, .confirm-button {
  width: 100%;
}

.confirm-button {
  color: #fff;
  background-color: #007bff;
}

.confirm-button:hover {
  background-color: #0056b3;
}

.camera-feed, .photo-preview, .captured-photo {
  width: 100%;
  height: auto;
}

.login-header {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.collabria-full-logo {
  width: 27.5rem;
  height: auto;
  margin-bottom: 2.5rem;
}

.login-content {
  flex-direction: column;
  max-width: 50rem;
  height: 22.5rem;
  padding: 1rem;
  display: flex;
  overflow: hidden;
}

.login-content img {
  cursor: pointer;
  border-radius: .5rem;
  gap: 1rem;
  width: 10rem;
  height: auto;
  padding: .5rem;
  transition: transform .3s, opacity .3s;
}

.login-content img:hover {
  opacity: .85;
  transform: scale(1.1);
  box-shadow: 0 4px 8px #0003;
}

.signin-content, .create-account-content, .login-info {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.login-info input {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: .5rem;
  width: 100%;
  max-width: 18.75rem;
  padding: .75rem 1rem;
  font-size: 1rem;
  transition: border-color .3s, box-shadow .3s;
  box-shadow: inset 0 2px 4px #0000001a;
}

.login-info input:focus {
  background-color: #fff;
  border-color: #efca08;
  outline: none;
  box-shadow: 0 0 5px #bea30bae;
}

.login-content button {
  color: #fff;
  cursor: pointer;
  background-color: #efca08;
  border: none;
  margin: 0;
}

.login-content button:hover {
  background-color: #bd9f07;
}

.toggle-option {
  margin-top: 2rem;
  font-family: Montserrat, Helvetica;
  font-size: .825rem;
}

.toggle-option a {
  color: #bd9f07;
}

.input-content {
  width: 100%;
  margin-bottom: 1.5rem;
}

.input-content .input-error {
  border: 1px solid red;
  box-shadow: 0 0 8px #ff000080;
}

.error-text {
  color: red;
  margin-top: .5rem;
  font-family: Montserrat, Helvetica;
  font-size: .75rem;
}

.edit-avatar-content {
  flex-direction: column;
  justify-content: space-between;
  max-width: 50rem;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.all-avatars {
  background-color: #fff;
  flex-direction: column;
  min-width: 55%;
  height: 15rem;
  padding: .5rem;
  display: flex;
}

.customize-scrollable {
  scroll-behavior: smooth;
  flex-grow: 1;
  gap: .5rem;
  height: 100%;
  display: flex;
  overflow-x: auto;
}

.customize-scrollable::-webkit-scrollbar {
  height: 8px;
}

.customize-scrollable::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  border-radius: 10px;
}

.avatar-item {
  text-align: center;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 100%;
  display: flex;
}

.avatar-item img {
  cursor: pointer;
  border-radius: .5rem;
  gap: 1rem;
  width: 100%;
  height: auto;
  margin: .5rem;
  padding: .5rem;
  transition: transform .3s, opacity .3s;
}

.avatar-item img:hover {
  opacity: .85;
  transform: scale(1.1);
  box-shadow: 0 4px 8px #0003;
}

@media (width <= 991px) {
  img {
    height: 90%;
  }

  .popup {
    margin: 1.5rem;
    padding: 1.5rem;
  }

  .popup h1 {
    font-size: 1.5rem;
  }

  .reward-title, .encouragement-title, .encouragement-detail, .reward-button, .close-button {
    font-size: .75rem;
  }

  .edit-avatar-content {
    max-width: 40rem;
  }
}

@media (width <= 767px) {
  .collabria-full-logo {
    width: 22.5rem;
  }

  img {
    width: 80%;
  }

  .popup {
    margin: 1rem;
    padding: 1rem;
  }

  .popup h1 {
    font-size: 1.25rem;
  }

  .reward-section, .encouragement-section {
    width: 8rem;
  }

  .reward-title, .encouragement-title, .encouragement-detail, .reward-button, .close-button {
    font-size: .625rem;
  }

  .edit-avatar-content {
    max-width: 25rem;
  }
}

@media (width <= 478px) {
  .collabria-full-logo {
    width: 20rem;
  }

  .rewards {
    flex-direction: column;
  }

  .popup {
    margin: .5rem;
    padding: .5rem;
  }

  .popup h1 {
    font-size: 1rem;
  }

  .reward-section, .encouragement-section {
    width: 12.5rem;
  }

  .reward-title, .encouragement-title, .encouragement-detail, .reward-button, .close-button {
    font-size: .5rem;
  }

  .edit-avatar-content {
    max-width: 20rem;
  }
}

.profile-content {
  background-color: #d9d9d9;
  flex-direction: row;
  width: 100%;
  margin: .25rem;
  padding: .25rem;
  display: flex;
}

.avatar-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 45%;
  height: 100vh;
  display: flex;
}

.edit-avatar {
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  display: flex;
}

.edit-button {
  cursor: pointer;
  border: none;
  border-radius: 3rem;
  padding: .5rem 1rem;
  font-family: Montserrat, Helvetica;
  font-size: .825rem;
}

.names {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.name {
  margin-bottom: .5rem;
  font-family: Montserrat, Helvetica;
  font-size: 1.5rem;
  font-weight: 700;
}

.titles {
  flex-direction: row;
  align-items: center;
  gap: .5rem;
  margin: 0;
  padding: 0;
  display: flex;
}

.badge {
  width: auto;
  height: 2rem;
}

.title {
  margin: 0;
  font-family: Montserrat, Helvetica;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 300;
}

.number-questions {
  margin-top: 2.5rem;
  font-family: Montserrat, Helvetica;
  font-size: 2rem;
  font-weight: 900;
}

.description-questions {
  margin-top: .25rem;
  font-family: Montserrat, Helvetica;
  font-size: .75rem;
  font-weight: 100;
}

.customize-content {
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.collection {
  background-color: #fff;
  flex-direction: column;
  min-width: 55%;
  height: 32.5%;
  padding: .5rem;
  display: flex;
  overflow: hidden;
}

.customize-scrollable {
  flex-grow: 1;
  gap: .5rem;
  display: flex;
  overflow-x: auto;
}

.badge-item, .title-item, .accessory-item {
  text-align: center;
  background-color: #d3d3d3;
  flex-direction: column;
  flex-shrink: 0;
  place-content: center;
  place-items: center;
  width: 10rem;
  height: 100%;
  display: flex;
}

.customize {
  margin-bottom: .25rem;
  font-family: Montserrat, Helvetica;
  font-size: 1.25rem;
  font-weight: 500;
}

@media (width <= 991px) {
  img {
    width: 70%;
    height: auto;
  }
}

@media (width <= 767px) {
  .profile-content {
    flex-direction: column;
  }

  .avatar-content {
    width: 100%;
  }

  .customize-content {
    width: 100%;
    height: 100%;
  }

  img {
    width: 50%;
    height: auto;
  }
}

@media (width <= 478px) {
  img {
    width: 40%;
    height: auto;
  }
}

.question-prompts {
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  padding: 1.25rem;
  display: flex;
}

.question-prompt {
  color: #000;
  cursor: pointer;
  border: none;
  border-radius: .625rem;
  justify-content: center;
  align-items: center;
  width: 22.5%;
  height: 100%;
  padding: 1.25rem;
  font-family: Montserrat, Helvetica;
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  position: relative;
  overflow: hidden;
}

.question-prompt:before {
  content: "";
  opacity: .4;
  z-index: 1;
  background-position: center;
  background-size: cover;
  position: absolute;
  inset: 0;
}

.prompt-1:before {
  background-image: url("prompt-1.b994c51c.png");
}

.prompt-2:before {
  background-image: url("prompt-2.7fa53a1d.png");
}

.prompt-3:before {
  background-image: url("prompt-3.53614957.png");
}

.question-prompt p {
  z-index: 2;
  position: relative;
}

@media (width <= 991px) {
  .question-prompts {
    gap: 1rem;
    padding: 1rem;
  }

  .question-prompt {
    width: 30%;
    padding: 1rem;
    font-size: 1.25rem;
  }
}

@media (width <= 767px) {
  .question-prompts {
    flex-direction: column;
    gap: .75rem;
    padding: .75rem;
  }

  .question-prompt {
    background-image: none;
    width: 80%;
    padding: .75rem;
    font-size: 1.125rem;
  }

  .question-prompt:before {
    background-image: none;
  }
}

@media (width <= 478px) {
  .question-prompts {
    gap: .5rem;
    padding: .5rem;
  }

  .question-prompt {
    background-image: none;
    width: 100%;
    padding: .5rem;
    font-size: 1rem;
  }

  .question-prompt:before {
    background-image: none;
  }
}

.conversations-content {
  background-color: #d9d9d9;
  flex-direction: column;
  width: 100%;
  margin: .25rem;
  padding: 1.75rem;
  display: flex;
}

.conversations-name {
  margin-bottom: 2rem;
  font-family: Montserrat, Helvetica;
  font-size: 2rem;
  font-weight: 700;
}

.conversations-content .student-content {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-right: 1rem;
  display: flex;
}

.student-content .profile-picture-content {
  margin-right: 1rem;
}

.conversations-wrapper {
  background-color: #f9f9f9;
  border-radius: .5rem;
  height: 35rem;
  margin: 2.5rem 0;
  padding: 5rem 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.conversation-wrapper {
  flex-direction: row;
  place-items: center;
  width: 100%;
  margin: 0 2.5rem;
  display: flex;
}

.conversation-content .chatbox {
  border: .125rem solid #efca08;
  border-radius: .25rem;
  flex-direction: column;
  flex-grow: 1;
  gap: .625rem;
  width: 100%;
  max-height: 25rem;
  padding: .825rem;
  display: flex;
  overflow-y: auto;
}

.message {
  margin-bottom: .825rem;
}

.text {
  margin-left: .825rem;
}

.slider {
  writing-mode: vertical-lr;
  vertical-align: top;
  direction: ltr;
  align-items: center;
  height: 100%;
  padding-left: 1rem;
  display: flex;
}

.slider input[type="range"] {
  appearance: none;
  writing-mode: bt-lr;
  background: #fff;
  border: 2px solid #ddd;
  border-radius: .5rem;
  outline: none;
  height: 100%;
  margin: 0;
  padding: 0;
}

.slider input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  cursor: pointer;
  background: #efca08;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.slider input[type="range"]::-moz-range-thumb {
  cursor: pointer;
  background: #efca08;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.special-marker {
  cursor: pointer;
  background-color: #efca08;
  border-radius: 50%;
  width: .75rem;
  height: .75rem;
  position: absolute;
}

.marquee-top, .marquee-bottom {
  white-space: nowrap;
  color: #333;
  background-color: #fff;
  width: 100%;
  padding: 10px 0;
  font-family: Montserrat, Helvetica;
  font-size: 16px;
  font-weight: 100;
  position: absolute;
  overflow: hidden;
}

.marquee-top {
  border-bottom: 2px solid #efca08;
  top: 0;
}

.marquee-bottom {
  border-top: 2px solid #efca08;
  bottom: 0;
}

@keyframes marqueeTop {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes marqueeBottom {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

.marquee-top p {
  animation: 20s linear infinite marqueeTop;
}

.marquee-bottom p {
  animation: 20s linear infinite marqueeBottom;
}

@media (width <= 991px) {
  .conversations-name {
    font-size: 1.75rem;
  }
}

@media (width <= 767px) {
  .conversations-name {
    font-size: 1.5rem;
  }
}

@media (width <= 478px) {
  .conversations-name {
    font-size: 1.25rem;
  }
}

.student-item {
  cursor: pointer;
  background-color: #fff;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: .75rem;
  font-size: 1.25rem;
  display: flex;
}

.student-content {
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-right: 1rem;
  display: flex;
}

.profile-picture-content {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  display: flex;
  overflow: hidden;
}

.profile-picture {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.status-content {
  flex-direction: column;
  align-items: flex-start;
  gap: .75rem;
  display: flex;
}

.name-content {
  flex-direction: row;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.student-name {
  margin: 0;
  padding: 0;
  font-family: Montserrat, Helvetica;
  font-size: 1.75rem;
  font-weight: 900;
}

.engagement {
  width: 1.75rem;
  height: 1.75rem;
}

.active {
  font-family: Montserrat, Helvetica;
  font-size: 1rem;
  font-weight: 100;
}

.alert-content {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.alert {
  width: 2.5rem;
  height: 2.5rem;
}

.carrot-profile {
  cursor: pointer;
  width: 3rem;
  height: 3rem;
}

@media (width <= 991px) {
  .student-name {
    font-size: 1.5rem;
  }
}

@media (width <= 767px) {
  .profile-picture-content {
    width: 4rem;
    height: 4rem;
  }

  .student-content {
    gap: .75rem;
  }

  .student-name {
    font-size: 1.25rem;
  }

  .active {
    font-size: .75rem;
  }
}

@media (width <= 478px) {
  .student-name {
    font-size: 1.25rem;
  }
}

.students-content {
  background-color: #d9d9d9;
  flex-direction: column;
  width: 100%;
  margin: .25rem;
  padding: 1.75rem;
  display: flex;
}

.students-name {
  margin-bottom: 2rem;
  font-family: Montserrat, Helvetica;
  font-size: 2rem;
  font-weight: 700;
}

.search-bar {
  border: none;
  border-radius: 3rem;
  width: 100%;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  font-size: 1rem;
}

.students-list {
  flex-flow: column;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  overflow-x: auto;
}

@media (width <= 991px) {
  .students-name {
    font-size: 1.75rem;
  }
}

@media (width <= 767px) {
  .students-name {
    font-size: 1.5rem;
  }

  .students-content {
    padding: 1.5rem;
  }
}

@media (width <= 478px) {
  .students-name {
    font-size: 1.25rem;
  }

  .students-content {
    padding: 1.25rem;
  }
}

.star {
  color: #e4e5e9;
  margin: 0;
  padding: 0;
  font-size: 30px;
}

.earned-star {
  color: #f16100;
  animation: .5s grow, 1s ease-in-out spark;
}

@keyframes grow {
  0% {
    transform: scale(.5);
  }

  20% {
    transform: scale(.75);
  }

  40% {
    transform: scale(1.125);
  }

  60% {
    transform: scale(1.5);
  }

  80% {
    transform: scale(1.125);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes spark {
  0% {
    filter: brightness();
  }

  20% {
    filter: brightness(1.2);
  }

  40% {
    filter: brightness(1.5);
  }

  60% {
    filter: brightness(1.7);
  }

  80% {
    filter: brightness(1.5);
  }

  100% {
    filter: brightness(1.2);
  }
}

@media (width <= 991px) {
  .star {
    font-size: 30px;
  }
}

@media (width <= 767px) {
  .star {
    font-size: 25px;
  }
}

@media (width <= 478px) {
  .star {
    font-size: 20px;
  }
}

.levelup-content {
  background-color: #fff;
  border-radius: .5rem;
  flex-direction: column;
  margin: .5rem;
  padding: .75rem;
  display: flex;
}

.levelup-score {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
  display: flex;
}

.levelup-percentage {
  color: #000;
  font-family: Montserrat, Helvetica;
  font-size: 1.25rem;
  font-weight: bold;
}

.stars {
  flex-direction: row;
  display: flex;
}

.levelup-wrapper {
  align-items: center;
  width: 30rem;
  height: 1.5rem;
  margin-top: .25rem;
  display: flex;
}

.levelup-container {
  background-color: #006bb9;
  border: .25rem solid #0b77c2;
  border-radius: 1.25rem;
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: inset 0 2px 4px #00000060;
}

.levelup-fill {
  background-image: linear-gradient(90deg, #fdc972, #ff9e2c);
  border-radius: .625rem;
  width: 0;
  height: 100%;
  transition: width .5s;
  box-shadow: 0 2px 5px #f35e0399;
}

@media (width <= 991px) {
  .levelup-percentage {
    font-size: 1.125rem;
  }

  .levelup-wrapper {
    width: 27.5rem;
    height: 1.25rem;
  }
}

@media (width <= 767px) {
  .levelup-percentage {
    font-size: 1rem;
  }

  .levelup-wrapper {
    width: 25rem;
    height: 1.125rem;
  }
}

@media (width <= 478px) {
  .levelup-percentage {
    font-size: 1rem;
  }

  .levelup-wrapper {
    width: 20rem;
    height: 1rem;
  }
}

.title-content {
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  display: flex;
}

.question-title {
  text-align: center;
  color: #000;
  text-overflow: ellipsis;
  background-color: #fff;
  border-radius: 3.125rem;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 50%;
  margin: .9375rem auto;
  padding: 1.5rem;
  font-family: Montserrat, Helvetica;
  font-weight: 700;
  display: flex;
  overflow: hidden;
}

.navigation {
  cursor: pointer;
  padding: 0 .5rem;
  font-size: 1.5rem;
}

.question-text {
  text-align: center;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  flex-grow: 1;
  overflow: hidden;
}

@media (width <= 991px) {
  .question-title {
    padding: 1.25rem;
  }
}

@media (width <= 767px) {
  .title-content {
    align-items: center;
  }

  .question-title {
    width: 80%;
    padding: 1rem;
  }
}

@media (width <= 478px) {
  .question-title {
    width: 80%;
    padding: .75rem;
  }
}

.star-container {
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 125px;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.big-star {
  color: #f16100;
  font-size: 75px;
  animation: 1.25s ease-in-out growAndSpark;
}

@keyframes growAndSpark {
  0% {
    filter: brightness();
    transform: scale(.5);
  }

  50% {
    filter: brightness(1.5);
    transform: scale(1.5);
  }

  100% {
    filter: brightness();
    transform: scale(1);
  }
}

.small-stars {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tiny-star {
  color: gold;
  opacity: 0;
  font-size: 25px;
  animation: 2.5s ease-out forwards popAndFly;
  position: absolute;
}

.star-1 {
  animation-delay: .2s;
  top: -30px;
  left: -30px;
}

.star-2 {
  animation-delay: .4s;
  top: -30px;
  right: -30px;
}

.star-3 {
  animation-delay: .6s;
  bottom: -30px;
  left: -30px;
}

.star-4 {
  animation-delay: .8s;
  bottom: -30px;
  right: -30px;
}

.star-5 {
  animation-delay: 1s;
  top: -50px;
}

@keyframes popAndFly {
  0% {
    opacity: 0;
    transform: scale(.5)translate(0);
  }

  50% {
    opacity: 1;
    transform: scale(1.5)translate(0);
  }

  100% {
    opacity: 0;
    transform: scale(1)translate(50px, 50px);
  }
}

.user-input-content {
  z-index: 0;
  flex-direction: row;
  align-items: flex-end;
  gap: 1rem;
  padding: .75rem .5rem;
  display: flex;
  position: sticky;
  bottom: 0;
}

.ask-content {
  background-color: #fff;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: .25rem;
  display: flex;
}

.ask-controls {
  align-items: center;
  width: 100%;
  margin-left: .3125rem;
  display: flex;
}

.mic-icon, .photo-icon {
  width: 2rem;
  height: 2rem;
}

.ask-text {
  font-family: var(--m3-label-medium-font-family);
  font-size: 1.125rem;
  font-weight: var(--m3-label-medium-font-weight);
  color: #000;
  resize: none;
  background-color: #f9f9f9;
  border: 0;
  border-radius: .3125rem;
  width: 100%;
  height: 1.5rem;
  min-height: 2.5rem;
  max-height: 10rem;
  margin-left: .625rem;
  margin-right: .625rem;
  padding: .5rem;
  overflow: auto;
}

.send-button {
  color: #fff;
  cursor: not-allowed;
  background-color: #d3d0d0;
  border: 0;
  margin: 0;
  padding: .625rem 1.25rem;
  font-size: 1.125rem;
}

.send-button--active {
  cursor: pointer;
  background-color: #ff9e2c;
}

.icon-content {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.student-icon {
  width: 4.6875rem;
  height: 4.6875rem;
}

.earned-points {
  color: #000;
  border-radius: .5rem;
  margin-bottom: .625rem;
  padding: .3125rem .625rem;
  font-family: Montserrat, Helvetica;
  font-size: 1rem;
  font-weight: 700;
  display: inline-block;
}

.points-notification {
  z-index: 1;
  align-items: center;
  display: inline-flex;
  position: absolute;
  top: -6.5rem;
  left: 50%;
  transform: translateX(-50%);
}

.star-icon {
  width: 4.0625rem;
  height: 4.0625rem;
}

.points-text {
  color: #000;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  font-family: Montserrat, Helvetica;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2rem;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.webcam-container {
  margin-top: 10px;
  position: relative;
}

.webcam-container video {
  border: 1px solid #ccc;
  width: 100%;
  height: auto;
}

.webcam-container button {
  margin-top: 5px;
}

@media (width <= 991px) {
  .ask-text {
    font-size: 1rem;
  }

  .earned-points {
    font-size: .875rem;
  }

  .student-icon {
    width: 4rem;
    height: 4rem;
  }

  .star-icon {
    width: 3.5rem;
    height: 3.5rem;
  }
}

@media (width <= 767px) {
  .ask-content {
    padding: .125rem;
  }

  .ask-text {
    font-size: .95rem;
  }

  .earned-points {
    font-size: .75rem;
  }

  .student-icon {
    width: 3.5rem;
    height: 3.5rem;
  }

  .star-icon {
    width: 3rem;
    height: 3rem;
  }
}

@media (width <= 478px) {
  .ask-content {
    padding: .125rem;
  }

  .ask-text {
    font-size: .875rem;
  }

  .earned-points {
    font-size: .6875rem;
  }

  .student-icon {
    width: 3rem;
    height: 3rem;
  }

  .star-icon {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.screen-content {
  background-color: #d9d9d9;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: .25rem;
  padding: .25rem;
  display: flex;
}

.dynamic-content {
  flex-direction: column;
  justify-content: center;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (width <= 991px) {
  .dynamic-content {
    padding: 1rem;
  }
}

@media (width <= 767px) {
  .dynamic-content {
    padding: .75rem;
  }
}

@media (width <= 478px) {
  .dynamic-content {
    padding: .5rem;
  }
}

:root {
  --react-pdf-annotation-layer: 1;
  --annotation-unfocused-field-background: url("data:image/svg+xml;charset=UTF-8,<svg width='1px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill:rgba(0, 54, 255, 0.13);'/></svg>");
  --input-focus-border-color: Highlight;
  --input-focus-outline: 1px solid Canvas;
  --input-unfocused-border-color: transparent;
  --input-disabled-border-color: transparent;
  --input-hover-border-color: black;
  --link-outline: none;
}

@media screen and (forced-colors: active) {
  :root {
    --input-focus-border-color: CanvasText;
    --input-unfocused-border-color: ActiveText;
    --input-disabled-border-color: GrayText;
    --input-hover-border-color: Highlight;
    --link-outline: 1.5px solid LinkText;
  }

  .annotationLayer .textWidgetAnnotation :is(input, textarea):required, .annotationLayer .choiceWidgetAnnotation select:required, .annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input:required {
    outline: 1.5px solid selecteditem;
  }

  .annotationLayer .linkAnnotation:hover {
    backdrop-filter: invert();
  }
}

.annotationLayer {
  pointer-events: none;
  transform-origin: 0 0;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
}

.annotationLayer[data-main-rotation="90"] .norotate {
  transform: rotate(270deg)translateX(-100%);
}

.annotationLayer[data-main-rotation="180"] .norotate {
  transform: rotate(180deg)translate(-100%, -100%);
}

.annotationLayer[data-main-rotation="270"] .norotate {
  transform: rotate(90deg)translateY(-100%);
}

.annotationLayer canvas {
  width: 100%;
  height: 100%;
  position: absolute;
}

.annotationLayer section {
  text-align: initial;
  pointer-events: auto;
  box-sizing: border-box;
  transform-origin: 0 0;
  margin: 0;
  position: absolute;
}

.annotationLayer .linkAnnotation {
  outline: var(--link-outline);
}

.annotationLayer :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton) > a {
  width: 100%;
  height: 100%;
  font-size: 1em;
  position: absolute;
  top: 0;
  left: 0;
}

.annotationLayer :is(.linkAnnotation, .buttonWidgetAnnotation.pushButton) > a:hover {
  opacity: .2;
  background: #ff0;
  box-shadow: 0 2px 10px #ff0;
}

.annotationLayer .textAnnotation img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea), .annotationLayer .choiceWidgetAnnotation select, .annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input {
  background-image: var(--annotation-unfocused-field-background);
  border: 2px solid var(--input-unfocused-border-color);
  box-sizing: border-box;
  font: calc(9px * var(--scale-factor)) sans-serif;
  vertical-align: top;
  width: 100%;
  height: 100%;
  margin: 0;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):required, .annotationLayer .choiceWidgetAnnotation select:required, .annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input:required {
  outline: 1.5px solid red;
}

.annotationLayer .choiceWidgetAnnotation select option {
  padding: 0;
}

.annotationLayer .buttonWidgetAnnotation.radioButton input {
  border-radius: 50%;
}

.annotationLayer .textWidgetAnnotation textarea {
  resize: none;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea)[disabled], .annotationLayer .choiceWidgetAnnotation select[disabled], .annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input[disabled] {
  border: 2px solid var(--input-disabled-border-color);
  cursor: not-allowed;
  background: none;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):hover, .annotationLayer .choiceWidgetAnnotation select:hover, .annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input:hover {
  border: 2px solid var(--input-hover-border-color);
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):hover, .annotationLayer .choiceWidgetAnnotation select:hover, .annotationLayer .buttonWidgetAnnotation.checkBox input:hover {
  border-radius: 2px;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):focus, .annotationLayer .choiceWidgetAnnotation select:focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
  background: none;
  border-radius: 2px;
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) :focus {
  background-color: #0000;
  background-image: none;
}

.annotationLayer .buttonWidgetAnnotation.checkBox :focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
  border-radius: 2px;
}

.annotationLayer .buttonWidgetAnnotation.radioButton :focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before, .annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after, .annotationLayer .buttonWidgetAnnotation.radioButton input:checked:before {
  content: "";
  background-color: canvastext;
  display: block;
  position: absolute;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before, .annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
  width: 1px;
  height: 80%;
  left: 45%;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before {
  transform: rotate(45deg);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
  transform: rotate(-45deg);
}

.annotationLayer .buttonWidgetAnnotation.radioButton input:checked:before {
  border-radius: 50%;
  width: 50%;
  height: 50%;
  top: 20%;
  left: 30%;
}

.annotationLayer .textWidgetAnnotation input.comb {
  padding-left: 2px;
  padding-right: 0;
  font-family: monospace;
}

.annotationLayer .textWidgetAnnotation input.comb:focus {
  width: 103%;
}

.annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input {
  appearance: none;
}

.annotationLayer .popupTriggerArea {
  width: 100%;
  height: 100%;
}

.annotationLayer .fileAttachmentAnnotation .popupTriggerArea {
  position: absolute;
}

.annotationLayer .popupWrapper {
  font-size: calc(9px * var(--scale-factor));
  min-width: calc(180px * var(--scale-factor));
  pointer-events: none;
  width: 100%;
  position: absolute;
}

.annotationLayer .popup {
  max-width: calc(180px * var(--scale-factor));
  box-shadow: 0 calc(2px * var(--scale-factor)) calc(5px * var(--scale-factor)) #888;
  border-radius: calc(2px * var(--scale-factor));
  padding: calc(6px * var(--scale-factor));
  margin-left: calc(5px * var(--scale-factor));
  cursor: pointer;
  font: message-box;
  white-space: normal;
  word-wrap: break-word;
  pointer-events: auto;
  background-color: #ff9;
  position: absolute;
}

.annotationLayer .popup > * {
  font-size: calc(9px * var(--scale-factor));
}

.annotationLayer .popup h1 {
  display: inline-block;
}

.annotationLayer .popupDate {
  margin-left: calc(5px * var(--scale-factor));
  display: inline-block;
}

.annotationLayer .popupContent {
  margin-top: calc(2px * var(--scale-factor));
  padding-top: calc(2px * var(--scale-factor));
  border-top: 1px solid #333;
}

.annotationLayer .richText > * {
  white-space: pre-wrap;
  font-size: calc(9px * var(--scale-factor));
}

.annotationLayer .highlightAnnotation, .annotationLayer .underlineAnnotation, .annotationLayer .squigglyAnnotation, .annotationLayer .strikeoutAnnotation, .annotationLayer .freeTextAnnotation, .annotationLayer .lineAnnotation svg line, .annotationLayer .squareAnnotation svg rect, .annotationLayer .circleAnnotation svg ellipse, .annotationLayer .polylineAnnotation svg polyline, .annotationLayer .polygonAnnotation svg polygon, .annotationLayer .caretAnnotation, .annotationLayer .inkAnnotation svg polyline, .annotationLayer .stampAnnotation, .annotationLayer .fileAttachmentAnnotation {
  cursor: pointer;
}

.annotationLayer section svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.annotationLayer .annotationTextContent {
  opacity: 0;
  color: #0000;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
}

.annotationLayer .annotationTextContent span {
  width: 100%;
  display: inline-block;
}

:root {
  --react-pdf-text-layer: 1;
  --highlight-bg-color: #b400aa;
  --highlight-selected-bg-color: #006400;
}

@media screen and (forced-colors: active) {
  :root {
    --highlight-bg-color: Highlight;
    --highlight-selected-bg-color: ButtonText;
  }
}

[data-main-rotation="90"] {
  transform: rotate(90deg)translateY(-100%);
}

[data-main-rotation="180"] {
  transform: rotate(180deg)translate(-100%, -100%);
}

[data-main-rotation="270"] {
  transform: rotate(270deg)translateX(-100%);
}

.textLayer {
  text-align: initial;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  forced-color-adjust: none;
  transform-origin: 0 0;
  z-index: 2;
  line-height: 1;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.textLayer :is(span, br) {
  color: #0000;
  white-space: pre;
  cursor: text;
  transform-origin: 0 0;
  margin: 0;
  position: absolute;
}

.textLayer span.markedContent {
  height: 0;
  top: 0;
}

.textLayer .highlight {
  background-color: var(--highlight-bg-color);
  border-radius: 4px;
  margin: -1px;
  padding: 1px;
}

.textLayer .highlight.appended {
  position: initial;
}

.textLayer .highlight.begin {
  border-radius: 4px 0 0 4px;
}

.textLayer .highlight.end {
  border-radius: 0 4px 4px 0;
}

.textLayer .highlight.middle {
  border-radius: 0;
}

.textLayer .highlight.selected {
  background-color: var(--highlight-selected-bg-color);
}

.textLayer br::selection {
  background: none;
}

.textLayer .endOfContent {
  z-index: -1;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  display: block;
  position: absolute;
  inset: 100% 0 0;
}

.textLayer .endOfContent.active {
  top: 0;
}

.hiddenCanvasElement {
  width: 0;
  height: 0;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.progressbar-wrapper {
  align-items: center;
  width: 100%;
  height: .9375rem;
}

.progressbar-container {
  background-color: #fff;
  border: .125rem solid #fff;
  border-radius: .625rem;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.progressbar-fill {
  background-color: #efca08;
  border-radius: .625rem;
  width: 0;
  height: 100%;
  transition: width .5s;
}

.progressbar-percentage {
  color: #000;
  margin-left: .625rem;
  font-family: Montserrat, Helvetica;
  font-size: .875rem;
  font-weight: bold;
}

.frame {
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: .5rem;
  display: flex;
  overflow: hidden;
}

.main-content {
  flex-direction: row;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.upload-assignments-content {
  background-color: #d9d9d9;
  flex-direction: column;
  width: 100%;
  margin: .25rem;
  padding: 1.75rem;
  display: flex;
}

.content-header {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.upload-assignments-name {
  margin-bottom: 2rem;
  font-family: Montserrat, Helvetica;
  font-size: 2rem;
  font-weight: 700;
}

.upload-container {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  display: flex;
}

.uploaded-docs {
  flex-direction: column;
  width: 35%;
  margin-right: 1rem;
  display: flex;
}

.documents {
  margin: 0 0 1.25rem;
  padding: 0;
  font-family: Montserrat, Helvetica;
  font-size: 1.5rem;
  font-weight: 700;
}

.documents-list {
  flex-direction: column;
  width: 100%;
  max-height: 35rem;
  margin: 0;
  padding: 0;
  display: flex;
  overflow: hidden auto;
}

.document-item {
  background-color: #fff;
  border-radius: .5rem;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 7rem;
  margin-bottom: 1.25rem;
  padding: 1rem;
  transition: background-color .3s, box-shadow .3s;
  display: flex;
}

.drag-drop-item {
  background-color: #fff;
  border-radius: .5rem;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 5rem;
  margin: .5rem 0;
  padding: 1rem;
  transition: background-color .3s, box-shadow .3s;
  display: flex;
}

.document-item:hover {
  background-color: #ffff001a;
  box-shadow: 0 0 10px #ffff0080;
}

.document-icon img {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}

.document-info {
  width: 100%;
}

.document-info p {
  cursor: pointer;
  width: 100%;
  font-family: Montserrat, Helvetica;
  font-size: 1rem;
  font-weight: 100;
}

.document-info input {
  width: 100%;
  padding: .25rem;
  font-family: Montserrat, Helvetica;
  font-size: .825rem;
  font-weight: 100;
}

.document-details {
  margin-bottom: 1rem;
}

.date {
  width: 100%;
  font-family: Montserrat, Helvetica;
  font-size: .75rem;
  font-weight: 100;
}

.file-upload-area {
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.drag-drop-area {
  text-align: center;
  border: 2px dashed #ccc;
  flex-direction: column;
  place-content: center;
  place-items: center;
  gap: .5rem;
  width: 100%;
  height: 100%;
  margin-bottom: 1.25rem;
  padding: 2rem;
  transition: border .3s;
  display: flex;
}

.drag-drop-area:hover {
  background-color: #efe66d1a;
  border: 2px solid #efca08;
}

.drag-drop {
  margin: .75rem;
  font-family: Montserrat, Helvetica;
  font-size: 1rem;
  font-weight: 300;
}

.drag-drog-buttons {
  text-align: center;
  flex-direction: column;
  place-content: center;
  place-items: center;
  gap: .5rem;
  margin: .75rem;
  display: flex;
}

.drag-drop-button {
  cursor: pointer;
  border: none;
  border-radius: 3rem;
  margin: 0;
  padding: .5rem 1rem;
  font-family: Montserrat, Helvetica;
  font-size: .825rem;
  font-weight: 300;
}

.drag-drop-list-item {
  text-align: center;
  border: 2px dashed #ccc;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 1rem 0;
  padding: 2rem;
  transition: border .3s;
  display: flex;
}

.drag-drop-list-item:hover {
  background-color: #efe66d1a;
  border: 2px solid #efca08;
}

.documents-loading {
  margin-bottom: .75rem;
}

.document-content {
  flex-direction: column;
  gap: 1rem;
  width: 60%;
  padding: 1rem;
  transition: all .3s;
  display: flex;
}

.document-preview {
  background-color: #f9f9f9;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  padding: .5rem;
  overflow-y: auto;
}

.uploaded-preview {
  object-fit: cover;
  width: 100%;
  height: auto;
  max-height: none;
  overflow-y: auto;
}

.document-preview .react-pdf__Document {
  width: 100%;
  height: auto;
}

.questions-container {
  flex-direction: column;
  width: 100%;
  max-height: 20rem;
  margin: 0;
  padding: 0;
  display: flex;
  overflow: hidden auto;
}

textarea {
  resize: vertical;
  border: 1px solid #ccc;
  border-radius: .25rem;
  width: 100%;
  height: 50%;
  padding: .5rem;
  font-size: 1rem;
}

@media (width <= 991px) {
  .upload-assignments-name {
    font-size: 1.75rem;
  }

  .documents-list {
    max-height: 32.5rem;
  }
}

@media (width <= 767px) {
  .upload-assignments-name {
    font-size: 1.5rem;
  }

  .upload-assignments-content {
    padding: 1.5rem;
  }
}

@media (width <= 478px) {
  .upload-assignments-name {
    font-size: 1.25rem;
  }

  .upload-assignments-content {
    padding: 1.25rem;
  }
}
/*# sourceMappingURL=index.5767dbbc.css.map */

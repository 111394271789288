.ask-button {
    font-family: "Montserrat", Helvetica;
    font-size: 0.825rem;

    margin: 0;
    padding: 0;

    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.5rem;

    cursor: pointer;
}

.title-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
}

.question-title {
    font-family: "Montserrat", Helvetica;
    font-weight: 700;
    text-align: center;
    color: #000000;
    width: 80%;
    background-color: #ffffff;
    border-radius: 3.125rem;
    padding: 1.5rem;
    margin: 0.9375rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50%;
}

.navigation {
    cursor: pointer;
    font-size: 1.5rem;
    padding: 0 0.5rem;
}

.question-text {
    flex-grow: 1;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
}

/* Responsive styles */
@media (max-width: 991px) {
    .question-title {
        padding: 1.25rem;
    }
}

@media (max-width: 767px) {
    .title-content {
        align-items: center;
    }

    .question-title {
        padding: 1rem;
        width: 80%;
    }
}

@media (max-width: 478px) {
    .question-title {
        padding: 0.75rem;
        width: 80%;
    }
}
